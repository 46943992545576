import Navbar from './components/navbar/navbar';
import MainImage from './components/main_image/main_image';
import PageTittle from "./components/page_tittle/page_tittle";
import "./Contact.scss"
import { Phone, Mail, Room } from '@material-ui/icons';
import { ContactForm } from "gabro-framework"
import Footer from "./components/footer/footer"
import "./Calculate.scss"
const Calculate = () => {
    return <div className="calculate">
        <Navbar />
        <MainImage image={require("./img/kontakty.jpg")} height="380px" position="top">
            <PageTittle>Spočítejte si hypoteku</PageTittle>
        </MainImage>
        <div className="main_wraper">
            <div className="main_card">
                <iframe title="calculate" src="https://myform.cz/ytwgbk54jv"></iframe>
            </div>
        </div>
        <Footer />
    </div >
}

export default Calculate;