import "./Services.scss"
import Navbar from './components/navbar/navbar';
import MainImage from './components/main_image/main_image';
import PageTittle from "./components/page_tittle/page_tittle";
import Footer from "./components/footer/footer"
const IconCard = (props) => {
    return <div className="icon_card" style={{
        backgroundColor: "#ffffff",//props.bg,
        minHeight: props.height
    }}>
        <div className="icon_wrapper">
            <img src={props.icon} alt="icon" />
        </div>
        <div className="text_wrapper">
            {props.children}
        </div>
    </div>
}
const Services = () => {
    return <div className="services">
        <Navbar />
        <MainImage height="380px" position="center" image={require("./img/services.jpg")}>

            <PageTittle>Naše služby</PageTittle>
        </MainImage>
        <div className="card_wrapper">
            <IconCard height="500px" bg="rgb(226, 226, 226)" icon={require("../src/img/sporeni.png").default}>
                <h2>Spoření a investice</h2>
                <p>Spoření a investice jsou klíčovou součástí cesty za finanční svobodou člověka. Díky správným investicím vás touto cestou nasměrujeme. V portfoliu pro vás máme připraveny podílové fondy, burzovní investování, alternativní investice, komodity a v neposlední řadě stavební spoření, termínované vklady a doplňkové penzijní připojištění. Služby poskytujeme jak pro fyzické tak právnické osoby.</p>
                <h3>Nabízíme</h3>
                <ul>
                    <li>zprostředkování podílových fondů </li>
                    <li>burzovní investování</li>
                    <li>programy investičních společností</li>
                    <li>alternativní investice a komodity </li>
                    <li>stavení spoření</li>
                    <li>termínované vklady</li>
                    <li>doplňkové penzijní připojištění</li>
                </ul>

            </IconCard>

            <IconCard height="500px" bg="rgb(226, 226, 226)" icon={require("../src/img/prav_osoby.png").default}>
                <h2>Právnické osoby</h2>
                <p>Individuální péče o firmu v oblasti financování a pojištění je důležitá, a proto zajišťujeme na míru ušitý balík služeb pro konkrétní právnickou osobu. V případě, že rozjíždíte novou firmu a nevíte si rady, nabídneme vám naše cenné zkušenosti a vymyslíme nejlepší řešení pro vás a váš projekt. </p>
                <h3>Nabízíme</h3>
                <ul>
                    <li>Financování SVJ, BD </li>
                    <li>Investiční a provozní úvěry</li>
                    <li>Pojištění majetku firem, jejich činnosti a odpovědnosti</li>
                    <li>Pojištění vozového parku</li>
                    <li>Pojištění zaměstnanců</li>
                    <li>Financování a pojištění strojů</li>
                    <li>Operativní leasing</li>
                </ul>
            </IconCard>

            <IconCard height="480px" bg="rgb(226, 226, 226)" icon={require("../src/img/prav_osoby.png").default}>
                <h2>Pojištění</h2>
                <p>Podceňovat důležitost pojištění se nevyplácí, a proto vám zprostředkujeme pojištění všeho druhu od jakékoli pojišťovny na trhu. Pomůžeme vám nastavit parametry pojištění tak, aby vám vyhovovaly a zvolit optimální platbu za sjednaná rizika. Dále jsme také připraveni klientům pomoci při likvidaci pojistných událostí jak poradenstvím, tak potřebnou administrativou při likvidaci.  </p>
                <h3>Nabízíme</h3>
                <ul>
                    <li>majetkové pojištění </li>
                    <li>pojištění vozidel </li>
                    <li>životní pojištění </li>
                    <li>pojištění občanské odpovědnosti </li>
                    <li>krytí podnikatelských rizik </li>
                    <li>pojištění odpovědnosti z výkonu povolání </li>
                </ul>
            </IconCard>

            <IconCard height="480px" bg="rgb(226, 226, 226)" icon={require("../src/img/ikonka1.png").default}>
                <h2>Úvěry</h2>
                <p>Podle vašich požadavků vybereme vhodný produkt, tak aby byl co nejlevnější z hlediska nákladovosti a přesně odpovídal vašim představám. Pro zpracování nabídky používáme vždy ty nejaktuálnější informace a vypracování analýzy zdarma je u nás samozřejmostí. Zastupujeme banky v celé ČR a díky tomu vám ušetříme čas strávený vybíráním té nejlepší nabídky.  </p>
                <h3>Nabízíme</h3>

                <ul>
                    <li>hypotéky </li >
                    <li>úvěry ze stavebního spoření</li >
                    <li>spotřební úvěry</li >
                    <li>leasingy</li >
                    <li>investiční a provozní úvěry pro firmy</li >
                </ul>

            </IconCard>

            <IconCard height="250px" bg="rgb(226, 226, 226)" icon={require("../src/img/energ_plyn.png").default}>
                <h2>Energie a plyn</h2>
                <p>Dlouhodobě / Spolupracujeme s poradcem při výběru energií. Zajistíme vám tak kvalitní služby za férovou cenu. Definujte si společně s námi vaše požadavky jak by měla služba vypadat a poté již vše za vás zařídíme my.</p>

            </IconCard>
            <IconCard height="250px" bg="rgb(226, 226, 226)" icon={require("../src/img/mobil_tarif.png").default}>
                <h2>Zprostředkování mobilních tarifů </h2>
                <p>Naším partnerem v oblasti zprostředkování mobilních tarifů je K-level. Společně nabízíme zprostředkování všech hlavních operátorů (T-Mobile, Vodafone, O2). Výhodou našich služeb jsou například neomezená volání a SMS za 305 Kč měsíčně nebo neomezená volání a SMS + 2G dat jen za 395 Kč a dalších mnoho možností. Jako klient za námi nemusíte chodit a vše spolu vyřešíme online. </p>

            </IconCard>
            <IconCard height="300px" bg="rgb(226, 226, 226)" icon={require("../src/img/ikonka1.png").default}>
                <h2>Prodej a nákup nemovitostí  </h2>
                <p>Pokud potřebujete rychle a výhodně prodat, či koupit nemovitost, zvládneme celý proces vyřešit přesně podle vašich představ. Postaráme se o veškeré právní aspekty, realizaci financování i tržní odhad nemovitosti - <a href="https://www.reality2u.cz/sluzby/#trzni-odhad-nemovitosti">ten u nás budete mít zcela zdarma</a>.  </p>
                <p>
                    V oblasti realit totiž spolupracujeme s profesionály z Reality2u a máme tak k dispozici široké zázemí expertů v oboru.
                </p>

            </IconCard>

        </div>

        <Footer />
    </div>
}

export default Services