import { Button, Link } from "@material-ui/core";
import "./footer.scss"

const Footer = (props) => {

    return <div className="c_footer" >
        {/* <img src={require("../../img/logo.png").default} alt="logo" /> */}
        <div className="foo_text foo_link_wrapper">
            <h2>Odkazy</h2>
            <Link className="foo_link" href="/aboutus">O nás</Link><br />
            <Link className="foo_link" href="/services">Naše služby</Link><br />
            <Link className="foo_link" href="/calculate">Spočítejte si hypotéku</Link><br />
            <Link className="foo_link" href="/contact">Kontakt</Link>
        </div>
        <div className="foo_text foo_contact_wrapper">
            <h2>Kontakty</h2>
            <Button href="/contact" variant="contained">Napište nám!</Button>
            <p>
                info@nafin.cz<br />
                +420 736 108 295 - Renata Navrátilová <br />
                +420 737 931 559 - Petr Navrátil

            </p>
        </div>
    </div>
}

export default Footer;