import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import { Switch, Route } from 'react-router-dom';
// pages
import App from './App';
import AboutUs from "./AboutUs"
import Services from "./Servives"
import Contact from "./Contact"
import Calculate from "./Calculate"
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/aboutus" exact={true} component={AboutUs}></Route>
        <Route path="/services" exact={true} component={Services}></Route>
        <Route path="/contact" exact={true} component={Contact}></Route>
        <Route path="/calculate" exact={true} component={Calculate}></Route>
        <Route path="/" component={App}></Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
