import Navbar from './components/navbar/navbar';
import MainImage from './components/main_image/main_image';
import PageTittle from "./components/page_tittle/page_tittle";
import "./Contact.scss"
import { Phone, Mail, Room } from '@material-ui/icons';
import { ContactForm } from "gabro-framework"
import Footer from "./components/footer/footer"
import { Divider } from '@material-ui/core';
const Contact = () => {
    let state = {
        mailAdress: "",
        mailText: "",
        nottOpen: false,
        nottText: ""
    }
    return <div className="contact">
        <Navbar />
        <MainImage image={require("./img/kontakty.jpg")} height="380px" position="top">

            <PageTittle>Kontakt</PageTittle>
        </MainImage>

        <div className="form_holder">
            <div className="section_wrapper">
                <div className="contact_list">
                    <h2>Napište nám!</h2>
                    <h3>Nafin s.r.o.</h3>
                    <span><Mail /> info@nafin.cz  </span>
                    <span><Room /> Kpt. Nálepky 765/4, 674 01 Třebíč</span>

                    <p><b>Renata Navrátilová</b></p>
                    <span><Phone /> +420 736 108 295</span>
                    <p><b>Petr Navrátil</b></p>
                    <span><Phone /> +420 737 931 559</span>
                    <p><b>Fakturační adresa:</b><br />
                        Nafin s.r.o.<br />
                        Kamenná 60, 675 03 Kamenná<br />
                        IČ: 09700218</p>
                </div>
                <div className="contact_form">
                    <ContactForm formColor="#1297cc" buttonColor="#1297cc" />
                </div>
            </div>
        </div>
        <Footer />
    </div>
}
export default Contact;