import React from "react";
import "./navbar.scss"
import LogoImg from "../../img/logo.png";
import { IconButton } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { Drawer } from "@material-ui/core";
import { ButtonBase } from "@material-ui/core";
import { BrowserView, isDesktop, isMobile, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open_drawer: false,
            width: window.innerWidth,
            display_logo: props.show_logo ?? true

        };
        this.resize = () => {
            this.setState({
                width: window.innerWidth
            })
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }
    OpenDrawer() {
        this.setState({
            open_drawer: true,
        })
    }
    CloseDrawer() {
        this.setState({
            open_drawer: false,
        })
    }
    render() {
        let buttons = [
            <ButtonBase component={Link} to="/aboutus" className="menu_item" key={1}>
                O NÁS
            </ButtonBase>,
            <ButtonBase component={Link} to="/services" className="menu_item" key={0} >
                NAŠE SLUŽBY
            </ButtonBase >,

            <ButtonBase component={Link} to="/calculate" className="menu_item" key={3}>
                SPOČÍTEJTE SI HYPOTEKU
            </ButtonBase>,
            <ButtonBase component={Link} to="/contact" className="menu_item" key={2}>
                KONTAKT
            </ButtonBase>
        ]
        let button_menu = <IconButton onClick={() => this.OpenDrawer()}> <MenuIcon /> </IconButton>
        if (isDesktop && this.state.width > 900) {
            button_menu = buttons;
        }
        return <React.Fragment>
            <Drawer className="menu_drawer" anchor="top" open={this.state.open_drawer} onClose={() => this.CloseDrawer()}>
                <div className="menu_wrapper">
                    {buttons}
                </div>
            </Drawer>
            <div className="custom_navbar">
                <div className="logo_wrapper">
                    {this.state.display_logo ?
                        <Link to="/">
                            <img src={LogoImg} alt="logo" className="logo" />
                        </Link> : ""}
                </div>
                <div className="menu_wrapper">
                    {button_menu}
                </div>
            </div>
        </React.Fragment>
    }
}

export default Navbar;