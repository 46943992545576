
import './App.scss';
import Navbar from './components/navbar/navbar';
import MainImage from './components/main_image/main_image';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { BrowserView, isDesktop, isMobile, MobileView } from 'react-device-detect';
import Footer from "./components/footer/footer"
import Cookies from 'universal-cookie';
const Card = (props) => {
  return <div className="Card">
    {props.children}
  </div>
}

function App() {
  let image = require("../src/img/landingpage_desktop.png");
  if (isMobile) {
    image = require("../src/img/landingpage_phone.png");
  }
  return (
    <div className="App">
      <Navbar show_logo={false} />
      <MainImage position="bottom" image={image}>
        <MobileView>
          <div className="main_logo_wrapper">
            <img src={require("../src/img/logo.png").default} alt="NAFIN logo" className="logo" />
          </div>
        </MobileView>
        <div className="card-holder">
          <Card>
            <h2> <CountUp start={0} end={1600} duration={3.75} useEasing={true} /> </h2>
            <h3>spokojených klientů</h3>
          </Card>
          <Card>
            <h2> <CountUp start={0} end={1408} duration={3.55} useEasing={true} /> </h2>
            <h3>mil.kč v uzavřenách hypotékách</h3>
          </Card>
          <Card>
            <h2> <CountUp start={0} end={708} duration={3.15} useEasing={true} /> </h2>
            <h3>uzavřených hypotečních smluv</h3>
          </Card>
          <Card>
            <h2> <CountUp start={0} end={495} duration={3.75} useEasing={true} /> </h2>
            <h3>pojistných smluv</h3>
          </Card>
        </div>
      </MainImage>
      <div className="section">
        <BrowserView>
          <div className="logo_wrapper">

            <img src={require("../src/img/logo.png").default} alt="NAFIN logo" className="logo" />
          </div>
        </BrowserView>
        <div className="main_text">
          <p>
            Jsme rodinná firma, která se věnuje finančnímu poradenství.
            V průběhu více než desetileté praxe jsme vybudovali hodnoty, na nichž si dnes zakládáme a na které se aktuálně spoléhá přes 1600 klientů.
            Našimi zásadami jsou proto férový přístup, vzájemná důvěra, a především nalezení nejvýhodnějšího řešení pro naše klienty. Jsme tak v našem jednání opravdu flexibilní a dokážeme vyřešit potřeby jak fyzických osob, tak podnikatelů a firem, se kterými pracujeme.
            <Link to="/aboutus"> více </Link>
          </p>

        </div>
      </div>
      <Footer />
    </div >
  );
}

export default App;
