import Navbar from './components/navbar/navbar';
import MainImage from './components/main_image/main_image';
import "./AboutUs.scss"
import PageTittle from "./components/page_tittle/page_tittle"
import Footer from "./components/footer/footer"
function AbountUs() {
    return <div className="about_us">
        <Navbar />
        <MainImage height="380px" position="top" image={require("../src/img/ruce.jpg")}>
            <PageTittle>O nás</PageTittle>
        </MainImage>
        <div className="main_text_wrapper">
            <div className="main_text">
                {/* <h1>NAFiN</h1> */}
                <p>Poskytujeme komplexní finanční poradenství ve všech aspektech finančního trhu a spolupracujeme přitom se všemi významnými bankami i pojišťovnami - nejsme tak vázaní na jednoho velkého hráče, ale umíme pro vás vybrat a vyjednat tu nejvýhodnější nabídku. Dokážeme také pomoci klientům s horším prokazováním příjmů a mírným negativním záznamem v registrech. Každého klienta totiž zpracováváme individuálně, dle jeho životních potřeb a možností.
                </p><p>Ať už tedy hledáte na míru šité řešení vaší hypotéky, pojištění, nebo podnikatelského úvěru, u nás jste vždy správně.</p>
                <h2>Naši lidé</h2>
                <p>Jádro naší firmy tvoří partneři Renáta a Petr Navrátilovi. Finanční kariéra obou z nich odstartovala okolo roku 2010 ve stavební spořitelně ČSOB, odkud se po pěti letech společně vydali na cestu finančního makléře. Zkušenosti z bankovního sektoru zúročili v poradenské společnosti Money2u, které je dnes jejich firma Nafin cenným partnerem. V roce 2020 se nakonec rozhodli spojit svoje schopnosti ve vlastním podnikání a dát tak klientům možnost benefitovat z úzce spolupracující rodinné společnosti. </p>
                <p>Mimo pracovní život jsou Petr i Renata cestovateli a nadšenými sportovci - zatímco Petr hledá rozptýlení v motokrosu, Renáta se aktivně věnuje jezdectví.
                    V kanceláři i mimo ni je pro oba důležitá otevřenost a pohoda; přesně to se projevuje v jejich přístupu ke klientům a řešení jejich požadavků.</p>
                <p>Férovost a individualita jsou pak společnými jmenovateli pro práci celého NaFinu a právě díky nim si můžete být jisti, že jsou vaše finance v těch nejlepších rukou. </p>
                <h2>Naši partneři</h2>
                <p>Spolupracujeme se všemi pojišťovnami, bankami a investičními společnostmi na českém trhu. Našimi dalšími partnery jsou také MND podnikající v oblasti energie a plynu a mobilní tarify K-level.</p>
            </div>
        </div>
        <Footer />
    </div>
}

export default AbountUs;