import React from "react";
import "./main_image.scss"
import PageTittle from "../page_tittle/page_tittle"
class MainImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: this.props.image,
            position: this.props.position,
            height: this.props.height
        };
    }

    render() {
        const bg_gradient = "linear-gradient(0deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 0.8) 100%)";
        const bg_image = 'url("' + this.state.image.default + '")';
        const bg = bg_gradient + ", " + bg_image;
        return <div className="main_image" style={{ backgroundImage: bg, backgroundPosition: this.state.position, minHeight: this.state.height }}>
            {this.props.children}

        </div>
    }
}

export default MainImage;